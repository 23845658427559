<template>
	<a-spin :tip="spinnerLoaderLabel" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-3">
				<b>Marca</b>
				<a-select :allowClear="true" show-search option-filter-prop="children" :filter-option="filterOption" :loading="brandsSpinnerLoader" :disabled="brandsSpinnerLoader" class="text-uppercase"
						  :style="{ width: '100%' }" @change="getModelsByBrand" v-model="payload.brand_id">
					<a-select-option v-for="(brand, index) in motorcycleBrandList" :key="index" :value="brand.id">{{ brand.name.toUpperCase() }}</a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<b>Modelo</b>
				<a-select :allowClear="true" show-search option-filter-prop="children" :filter-option="filterOption" :loading="modelSpinnerLoader" class="text-uppercase" :style="{ width: '100%' }"
						  v-model="payload.model_id">
					<a-select-option v-for="(model, i) in motorcycleModelByBrandList" :key="i" :value="model.id"> {{ model.name }}</a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<b>Año</b>
				<a-input autocomplete="off" v-mask="'####'" v-model="payload.year" />
			</div>
			<div class="col-md-3">
				<b>Archivo</b>
				<div>
					<a-upload name="file" :file-list="file" :remove="handleRemove" :before-upload="beforeUpload">
						<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
					</a-upload>
				</div>
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12">
				<b>Comentarios</b>
				<a-textarea v-model="payload.comments"></a-textarea>
			</div>
		</div>
		<div class="row pt20">
			<div class="col-md-4 text-left">
				<a-button class="btn btn-warning" @click="onCancel">Cancelar</a-button>
			</div>
			<div class="col-md-8 text-right">
				<a-button icon="save" class="btn btn-success" @click="onSaveRecord">Guardar</a-button>
			</div>
		</div>
	</a-spin>
</template>
<script>
//
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'

export default {
	name: 'designDetailComponent',
	directives: {
		mask,
	},
	computed: {
		...mapGetters('designs', ['actualRecord', 'spinnerLoader', 'spinnerLoaderLabel']),
		...mapGetters('motorcycleBrand', ['motorcycleBrandList']),
		...mapGetters('motorcycleModels', ['motorcycleModelByBrandList']),
		isNewRecord() {
			return this.$route.params.id == 'new' ? true : false
		},
		brandsSpinnerLoader() {
			return this.$store.state.motorcycleBrand.spinnerLoader
		},
		modelSpinnerLoader() {
			return this.$store.state.motorcycleModels.spinnerLoader
		},
	},
	data() {
		return {
			payload: {
				brand_id: '',
				model_id: '',
				year: '',
				comments: '',
			},
			file: [],
		}
	},
	beforeMount() {
		if (this.motorcycleBrandList.length == 0) {
			this.$store.dispatch('motorcycleBrand/GET_BRANDS')
		}
	},
	methods: {
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		getModelsByBrand() {
			if (utilities.objectValidate(this.payload, 'brand_id', false)) {
				this.$store.dispatch('motorcycleModels/GET_MODELS_BY_BRAND', {
					brand_id: this.payload.brand_id,
				})
			}
		},
		handleRemove() {
			this.file = []
		},
		beforeUpload(file) {
			this.file = [file]
			return false
		},
		onCancel() {
			this.$router.push(`/disenios`)
		},
		onSaveRecord() {
			let errors = []

			if (this.payload.brand_id == '') {
				errors.push(`- Se requiere ingresar marca de la moto`)
			}
			if (this.payload.model_id == '') {
				errors.push(`- Se requiere ingresar modelo de la moto`)
			}
			if (this.payload.year == '') {
				errors.push(`- Se requiere ingresar año de la moto`)
			}
			if (this.isNewRecord && !this.file.length) {
				errors.push(`- Debes adjuntar archivo al registro`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Diseños',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			let formData = false

			if (this.file.length) {
				const { file } = this
				formData = new FormData()
				formData.append('file', file[0])
			}

			if (this.isNewRecord) {
				this.$store.dispatch('designs/CREATE', { payload: this.payload, formData }).then(() => {
					this.$router.replace('/disenios')
				})
			} else {
				this.payload = {
					...this.payload,
					id: this.$route.params.id,
				}
				this.$store.dispatch('designs/UPDATE', { payload: this.payload, formData })
			}
		},
	},
}
</script>
