<template>
    <div class="card">
        <div class="card-body">
            <designsDetailComponent />
        </div>
    </div>
</template>

<script>
import designsDetailComponent from '@/components/designs/details'

export default {
    name: 'DesignsDetailsView',
    components: {
        designsDetailComponent,
    },
    computed: {
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    },
}
</script>